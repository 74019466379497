<template>
    <table :align="avatarAlign" border="0">
        <tr>
            <td style="position: relative">
                <!-- Editor or Owner -->
                
                <!-- Upload avatar / default avatar -->
                <span v-if="avatarId === undefined || avatarId === 'undefined' || avatarId === null || avatarId === ''">
                    <fa v-if="avatarWidth === '30'" icon="user" style="width: 28px; height: 28px;  display: block" class="navbar-display-name"/>
                    <fa v-if="avatarWidth === '35'" icon="user" style="width: 32px; height: 32px" class="navbar-display-name"/>
                    <fa v-if="avatarWidth === '50'" icon="user" style="width: 48px; height: 48px" class="navbar-display-name"/>
                    <fa v-if="avatarWidth === '60'" icon="user" style="width: 58px; height: 58px" class="navbar-display-name"/>
                    <fa v-if="avatarWidth === '120'" icon="user" style="width: 116px; height: 116px" class="navbar-display-name"/>

                    <span v-if="avatarPrivilege === 'OWNER' || avatarPrivilege === 'EDITOR'">
                        <!-- Verified && OWNER/EDITOR -->

                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '30'" icon="square-pen" class="text-blue" style="z-index: 50; position: absolute; font-size: 15px; top: 16px; margin-left: -7px;" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '35'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; bottom: 0px; margin-left: -38px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '50'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; bottom: -3px; margin-left: -50px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '60'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; bottom: -3px; margin-left: -60px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '120'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 28px; bottom: -8px; margin-left: -120px" />
                        </Popper>

                        <span v-if="(avatarIsVerified === true || avatarIsVerified === 'true') && avatarAlign === 'left'">
                            <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 9px; margin-left: 27px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: -1px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -9px; margin-left: -3px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -10px; margin-left: -5px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -20px; margin-left: -10px; transform: translate(-50%, -50%)" />
                        </span>
                        <span v-if="(avatarIsVerified === true || avatarIsVerified === 'true') && avatarAlign === 'center'">
                            <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 9px; margin-left: 28px; transform: translate(-50%, -50%);" />
                            <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: -1px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -10px; margin-left: -4px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -10px; margin-left: -5px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -20px; margin-left: -10px; transform: translate(-50%, -50%)" />
                        </span>
                    </span>
                    <span v-if="!(avatarPrivilege === 'OWNER' || avatarPrivilege === 'EDITOR')">
                        <!-- Verified but not OWNER/EDITOR -->
                        <span v-if="(avatarIsVerified === true || avatarIsVerified === 'true') && avatarAlign === 'left'">
                            <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 28px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -6px; margin-left: -1px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -3px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                        </span>
                        <span v-if="(avatarIsVerified === true || avatarIsVerified === 'true') && avatarAlign === 'center'">
                            <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 13px; transform: translate(-50%, -50%);" />
                            <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -1px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -4px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                        </span>
                    </span>
                    
                    <span v-if="(avatarIsActive === 'DISABLED' || avatarIsActive === 'EXPIRED' || avatarIsActive === 'DELETING' || avatarIsActive === 'REACTIVE') && avatarAlign === 'left'">
                        <fa v-if="avatarWidth === '30'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 27px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -6px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -3px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-if="(avatarIsActive === 'DISABLED' || avatarIsActive === 'EXPIRED' || avatarIsActive === 'DELETING' || avatarIsActive === 'REACTIVE') && avatarAlign === 'center'">
                        <fa v-if="avatarWidth === '30'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 13px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -4px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-else-if="avatarIsActive === 'PENDING' && avatarAlign === 'left'">
                        <fa v-if="avatarWidth === '30'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 27px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -6px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -3px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-else-if="avatarIsActive === 'PENDING' && avatarAlign === 'center'">
                        <fa v-if="avatarWidth === '30'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 13px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -4px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-if="(avatarIsUser === false || avatarIsUser === 'false') && avatarAlign === 'left'">
                        <fa v-if="avatarWidth === '30'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 27px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -6px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -3px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-if="(avatarIsUser === false || avatarIsUser === 'false') && avatarAlign === 'right'">
                        <fa v-if="avatarWidth === '30'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 13px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -4px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    
                </span>
                <span v-else>
                    <img v-if="avatarWidth === '30' || avatarWidth === 30" :src="avatarSrc" class="rounded-circle" :width="avatarWidth" :height="avatarWidth"  style="object-fit: cover; display: block"/>
                    <img v-if="avatarWidth === '35' || avatarWidth === 35" :src="avatarSrc" class="rounded-circle" :width="avatarWidth" :height="avatarWidth"  style="object-fit: cover; display: block"/>
                    <img v-if="avatarWidth === '50' || avatarWidth === 50" :src="avatarSrc" class="rounded-circle" :width="avatarWidth" :height="avatarWidth"  style="object-fit: cover; display: block"/>
                    <img v-if="avatarWidth === '60' || avatarWidth === 60" :src="avatarSrc" class="rounded-circle" :width="avatarWidth" :height="avatarWidth"  style="object-fit: cover; display: block"/>
                    <img v-if="avatarWidth === '120' || avatarWidth === 120" :src="avatarSrc" class="rounded-circle" :width="avatarWidth" :height="avatarWidth"  style="object-fit: cover; display: block"/>

                    <span v-if="avatarPrivilege === 'OWNER' || avatarPrivilege === 'EDITOR'">
                        <!-- Verified && OWNER/EDITOR -->
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '30'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; bottom: 18px; margin-left: -7px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '35'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; bottom: 18px; margin-left: -5px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '50'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; bottom: 20px; margin-left: -2px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '60'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 15px; bottom: 23px; margin-left: 0px" />
                        </Popper>
                        <Popper class="popperDark" arrow hover :content="avatarPrivilege">
                            <fa v-if="avatarWidth === '120'" icon="square-pen" class="text-blue" style="z-index: 500; position: absolute; font-size: 28px; bottom: 20px; margin-left: 5px" />
                        </Popper>

                        <span v-if="(avatarIsVerified === true || avatarIsVerified === 'true') && avatarAlign === 'left'">
                            <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 11px; margin-left: 30px; transform: translate(-50%, -50%);" />
                            <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 10px; margin-left: 32px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 12px; margin-left: 45px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 16px; margin-left: 53px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; bottom: 8px; margin-left: 105px; transform: translate(-50%, -50%)" />
                        </span>
                        <span v-if="(avatarIsVerified === true || avatarIsVerified === 'true') && avatarAlign === 'center'">
                            <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 10px; margin-left: 30px; transform: translate(-50%, -50%);" />
                            <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 11px; margin-left: 33px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 12px; margin-left: 45px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: 15px; margin-left: 53px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; bottom: 6px; margin-left: 100px; transform: translate(-50%, -50%)" />
                        </span>
                    </span>
                    <span v-if="!(avatarPrivilege === 'OWNER' || avatarPrivilege === 'EDITOR')">
                        <!-- Verified but not OWNER/EDITOR -->
                        <span v-if="(avatarIsVerified === true || avatarIsVerified === 'true') && avatarAlign === 'left'">
                            <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 15px; transform: translate(-50%, -50%);" />
                            <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: 32px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: 45px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 53px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -10px; margin-left: 105px; transform: translate(-50%, -50%)" />
                        </span>
                        <span v-if="(avatarIsVerified === true || avatarIsVerified === 'true') && avatarAlign === 'center'">
                            <fa v-if="avatarWidth === '30'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 13px; transform: translate(-50%, -50%);" />
                            <fa v-if="avatarWidth === '35'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: 15px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '50'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: 20px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '60'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 23px; transform: translate(-50%, -50%)" />
                            <fa v-if="avatarWidth === '120'" icon="circle-check" class="text-success" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -10px; margin-left: 47px; transform: translate(-50%, -50%)" />
                        </span>
                    </span>
                    
                    <span v-else-if="(avatarIsActive === 'DISABLED' || avatarIsActive === 'EXPIRED' || avatarIsActive === 'DELETING' || avatarIsActive === 'REACTIVE') && avatarAlign === 'left'">
                        <fa v-if="avatarWidth === '30'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 15px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: 32px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: 45px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 53px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -10px; margin-left: 105px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-else-if="(avatarIsActive === 'DISABLED' || avatarIsActive === 'EXPIRED' || avatarIsActive === 'DELETING' || avatarIsActive === 'REACTIVE') && avatarAlign === 'center'">
                        <fa v-if="avatarWidth === '30'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 13px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: 15px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -8px; margin-left: 20px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 23px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="circle-minus" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -10px; margin-left: 47px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-else-if="avatarIsActive === 'PENDING' && avatarAlign === 'left'">
                        <fa v-if="avatarWidth === '30'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 15px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -6px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -3px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-else-if="avatarIsActive === 'PENDING' && avatarAlign === 'center'">
                        <fa v-if="avatarWidth === '30'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 13px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -4px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="hourglass-half" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-if="(avatarIsUser === false || avatarIsUser === 'false') && avatarAlign === 'left'">
                        <fa v-if="avatarWidth === '30'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 15px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -6px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -3px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                    <span v-if="(avatarIsUser === false || avatarIsUser === 'false') && avatarAlign === 'right'">
                        <fa v-if="avatarWidth === '30'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -7px; margin-left: 13px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '35'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -1px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '50'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -4px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '60'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 15px; bottom: -4px; margin-left: -5px; transform: translate(-50%, -50%)" />
                        <fa v-if="avatarWidth === '120'" icon="circle-question" class="text-danger" style="z-index: 1000; position: absolute; font-size: 28px; bottom: -9px; margin-left: -10px; transform: translate(-50%, -50%)" />
                    </span>
                </span>
                
            </td>
            <td v-if="avatarDisplayName" class="align-middle" :class="avatarClass" style="font-size: 14px">{{ avatarDisplayName }}</td>
        </tr>
    </table>

</template>

<script>
// Get user avatar
import {ref, inject, onMounted} from 'vue'
import Popper from 'vue3-popper'
import { useRoute } from 'vue-router'
import funcs from '@/functions/function'

export default {
    name: 'ImgAvatar',
    components: { Popper },
    props: [ 'id', 'firstName', 'lastName', 'displayName', 'class', 'width', 'height', 'isUser', 'isVerified', 'isActive', 'privilege', 'align' ],
    setup (props) {
        const axios = inject('axios')
        const route = useRoute()
        const func = funcs

        const avatarId = ref(props.id)
        const avatarSrc = ref(null) 

        const avatarDisplayName = ref(props.displayName)
        const avatarIsUser = ref(props.isUser)
        const avatarIsVerified = ref(props.isVerified)  // KYC verified
        const avatarIsActive = ref(props.isActive)
        const avatarPrivilege = ref(props.privilege)

        const avatarWidth = ref(props.width)
        const avatarHeight = ref(props.height)
        const avatarClass = ref(props.class)
        const avatarAlign = ref(props.align)    // left / center

        const marginTop = ref(22)
        const marginLeft = ref(28)

        onMounted(async () => {
            // console.info('- - - imgAvatar', avatarId.value)

            if (avatarWidth.value === '' || avatarWidth.value === undefined) {
                avatarWidth.value = '35'
            }

            if (avatarHeight.value === '' || avatarHeight.value === undefined) {
                avatarHeight.value = '35'
            }

            if (avatarAlign.value === '' || avatarAlign.value === undefined) {
                avatarAlign.value = "left"
            }

            axios.get( '/user/avatar/' + avatarId.value, { responseType: 'arraybuffer' }
                ).then((res) => {
                    const b64 = btoa(String.fromCharCode(...new Uint8Array(res.data)))
                    const imgData = 'data:' + res.headers['content-type'] + ';base64,' + b64
                    avatarSrc.value = imgData
                    // console.info('- - - imgAvatar', avatarSrc.value)
                })
                .catch((error) => {
                    func.addLog('comp: imgavatar', 'onMounted - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error + ' avatarId: ' + avatarId.value)
                })
        })

        return {
            avatarIsUser, avatarDisplayName, avatarIsVerified, avatarIsActive, 
            avatarClass, avatarWidth, avatarHeight, avatarAlign, avatarPrivilege, 
            marginTop, marginLeft,
            route, avatarId, avatarSrc
        }
    }
}
</script>

<style>

</style>